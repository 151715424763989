import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import { product, apiGet } from '../../services/api'
import Toolbar from '@/components/toolbar/toolbar';


export default {
    name: "sustainability-content",
    data() {
        return {
            product_list: null,
            chk3: false,
            form: {},
            baseFront: this.$baseFront
        }
    },
    methods: {
        async fetchSearch(id) {
            await this.$axios.get(`/api/show/sustainability_content/${id}`)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.form = response.data.data[0];
                        // JSON convert string array to array object
                    }
                })
            console.log(this.form);
        },
    },
    async mounted() {
        window.scrollTo(0, 0);
        await this.fetchSearch(this.$route.params.id)
        document.title = "AGC - Subtainability";
        AOS.init();
    },
    components: {
        Footer, Header, Breadcrumb, Toolbar
    }
};