import { render, staticRenderFns } from "./sustainability_content.component.html?vue&type=template&id=1394051c&scoped=true&"
import script from "./sustainability_content.component.js?vue&type=script&lang=js&"
export * from "./sustainability_content.component.js?vue&type=script&lang=js&"
import style0 from "./sustainability_content.component.scss?vue&type=style&index=0&id=1394051c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1394051c",
  null
  
)

export default component.exports